import { FC, ReactElement } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useThemeContext } from 'Components/context/muiTheme.tsx';

const ClientLoader: FC<{
    isLoading?: boolean;
    comment?: string | ReactElement;
}> = ({ isLoading = true, comment }) => {
    const { isDarkMode } = useThemeContext();
    return isLoading ? (
        <Box
            sx={{
                position: 'absolute',
                inset: 0,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 99,
                backgroundColor: isDarkMode
                    ? 'rgba(18, 18, 18, 0.85)'
                    : 'rgba(255, 255, 255, 0.65)',
            }}
        >
            <Box sx={{ position: 'relative' }}>
                <CircularProgress
                    size={68}
                    sx={{
                        color: isDarkMode ? 'rgba(255, 255, 255, 0.65)' : '#1687B7',
                    }}
                />
                {comment && (
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '100%',
                            mt: 3,
                            left: '50%',
                            transform: 'translateX(-50%)',
                            whiteSpace: typeof comment === 'string' ? 'nowrap' : 'normal',
                        }}
                    >
                        {typeof comment === 'string' ? (
                            <Typography variant="manrope_16">{comment}</Typography>
                        ) : (
                            comment
                        )}
                    </Box>
                )}
            </Box>
        </Box>
    ) : null;
};

export default ClientLoader;
