import React from 'react';
//
import { Box, Button, Typography } from '@mui/material';
//
import HomegrownSVG from 'Assets/homegrown.svg';
import CelebrationSRC from 'Assets/images/celebration.svg';

export const SecondaryInvestorInviteComplete = () => {
    return (
        <>
            <Box
                sx={{
                    padding: '20px 24px',
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: '32px',
                }}
            >
                <img src={HomegrownSVG} alt="Homegrown Logo" width={188} height={32} />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '32px',
                    flexDirection: 'column',
                    maxWidth: '720px',
                    width: '100%',
                    margin: '0 auto',
                    padding: '0 24px',
                }}
            >
                <Typography variant="spectral_32" sx={{ textAlign: 'center' }}>
                    Your submission is complete
                </Typography>
                <img src={CelebrationSRC} alt="Celebration " />
                <Button
                    variant="contained"
                    size="large"
                    fullWidth
                    onClick={() => window.open('https://www.joinhomegrown.com/')}
                >
                    Visit homegrown.com
                </Button>
            </Box>
        </>
    );
};
