import React, { FC, useRef, ChangeEvent, DragEvent } from 'react';
//
import { Box, Typography, LinearProgress, IconButton } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DeleteIcon from '@mui/icons-material/Delete';
//
import { useShowToast } from 'Helpers/hooks/useShowToast.tsx';
import { truncateFileName } from 'Helpers/utils.ts';
import { useBreakpointsContext } from './context/BreakpointsContext.tsx';

const MUIFileRow: FC<{
    file: File;
    onDelete: () => void;
    disabled: boolean;
}> = ({ file, onDelete, disabled }) => {
    const { isSmall } = useBreakpointsContext();

    return (
        <Box
            sx={{
                display: 'flex',
                marginTop: '8px',
                gap: '8px',
                padding: '16px',
                border: '1px solid rgba(0, 0, 0, 0.12)',
                borderRadius: '8px',
                alignItems: 'center',
                maxWidth: '100%',
                opacity: disabled ? 0.5 : 1,
            }}
        >
            <Box
                sx={{
                    padding: '10px 12px',
                }}
            >
                <UploadFileIcon />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                }}
            >
                <Typography variant="roboto_16">{truncateFileName(file.name, 5)}</Typography>
                <Box
                    sx={{
                        display: 'flex',
                        gap: isSmall ? '0' : '8px',
                        marginBottom: '4px',
                        flexDirection: isSmall ? 'column' : 'row',
                    }}
                >
                    <Typography variant="roboto_14">{(file.size / 1024).toFixed(2)} KB</Typography>
                    <Typography variant="roboto_14">•</Typography>
                    <Typography variant="roboto_14">Complete</Typography>
                </Box>
                <Box sx={{ width: '100%' }}>
                    <LinearProgress variant="determinate" value={100} />
                </Box>
            </Box>
            <IconButton onClick={onDelete} sx={{ flexShrink: 0 }} disabled={disabled}>
                <DeleteIcon
                    color="primary"
                    sx={{
                        width: '20px',
                        height: '20px',
                    }}
                />
            </IconButton>
        </Box>
    );
};

export const MUIFilesDragAndDrop: FC<{
    files: File[];
    onFilesAdded: (files: File[]) => void;
    onFileDelete: (file: File) => void;
    id: string;
    maxFiles?: number;
    disabled: boolean;
}> = ({ files, onFilesAdded, onFileDelete, id, maxFiles = 2, disabled = false }) => {
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const { showError } = useShowToast();

    const handleFilesAdded = (newFiles: File[]) => {
        if (disabled) {
            return;
        }

        const totalFiles = files.length + newFiles.length;

        if (totalFiles > maxFiles) {
            showError(`You can upload a maximum of ${maxFiles} files for this category.`);
            return;
        }

        const validFiles: File[] = [];
        newFiles.forEach((file) => {
            const fileType = file.type;
            const fileSize = file.size;
            const validTypes = ['application/pdf', 'image/jpeg', 'image/png'];
            const maxSize = 25 * 1024 * 1024;

            if (!validTypes.includes(fileType)) {
                showError('Invalid file type. Only PDF, JPG, and PNG are allowed.');
            } else if (fileSize > maxSize) {
                showError('File size exceeds 25MB.');
            } else if (
                files.some((f) => f.name === file.name && f.lastModified === file.lastModified)
            ) {
                showError('This file is already uploaded.');
            } else {
                validFiles.push(file);
            }
        });

        if (validFiles.length > 0) {
            onFilesAdded(validFiles);
        }
    };

    const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
        const newFiles = Array.from(event.target.files || []);
        handleFilesAdded(newFiles);
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const handleDragAndDrop = (event: DragEvent) => {
        event.preventDefault();
        if (disabled) {
            return;
        }
        const newFiles = Array.from(event.dataTransfer.files);
        handleFilesAdded(newFiles);
    };

    const handleDragOver = (event: DragEvent) => {
        event.preventDefault();
    };

    const handleDragEnter = (event: DragEvent) => {
        event.preventDefault();
    };

    const handleLabelClick = (event: React.MouseEvent<HTMLLabelElement>) => {
        if (disabled) {
            event.preventDefault();
        }
    };

    return (
        <Box>
            <Box
                sx={{
                    padding: '24px 78px',
                    marginTop: '6px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    border: '1px dashed rgba(0, 0, 0, 0.12)',
                    borderRadius: '4px',
                    textAlign: 'center',
                    opacity: disabled ? 0.5 : 1,
                    cursor: disabled ? 'not-allowed' : 'pointer',
                    pointerEvents: disabled ? 'none' : 'auto',
                }}
                onDragOver={handleDragOver}
                onDrop={handleDragAndDrop}
                onDragEnter={handleDragEnter}
            >
                <Box
                    sx={{
                        padding: '10px 12px',
                        marginBottom: '8px',
                    }}
                >
                    <UploadFileIcon />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        gap: '4px',
                        marginBottom: '8px',
                        flexDirection: 'column',
                    }}
                >
                    <Typography
                        component="label"
                        htmlFor={`file-upload-${id}`}
                        variant="manrope_16"
                        sx={{
                            fontWeight: '600',
                            lineHeight: '28px',
                            textDecoration: 'underline',
                            cursor: disabled ? 'not-allowed' : 'pointer',
                        }}
                        onClick={handleLabelClick}
                    >
                        Click to upload
                    </Typography>
                    <Typography variant="roboto_16">or drag and drop</Typography>
                </Box>
                <Typography
                    variant="roboto_14"
                    sx={{
                        fontWeight: 400,
                    }}
                >
                    PDF, JPG, or PNG (max. 25MB)
                </Typography>
                <input
                    ref={fileInputRef}
                    id={`file-upload-${id}`}
                    type="file"
                    multiple
                    style={{ display: 'none' }}
                    onChange={handleFileUpload}
                    disabled={disabled}
                />
            </Box>
            {files.map((file, index) => (
                <MUIFileRow
                    key={`${file.name}-${file.lastModified}-${index}`}
                    file={file}
                    onDelete={() => onFileDelete(file)}
                    disabled={disabled}
                />
            ))}
        </Box>
    );
};
