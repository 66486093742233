import React from 'react';
//
import { Box, Typography } from '@mui/material';
//
import HomegrownSVG from '../../Assets/homegrown.svg';
import EmptySRC from 'Assets/images/RafikiEmpty.svg';

export const NoSecondaryInvestorInvite = () => {
    return (
        <>
            <Box
                sx={{
                    padding: '20px 24px',
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: '32px',
                }}
            >
                <img src={HomegrownSVG} alt="Homegrown Logo" width={188} height={32} />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '32px',
                    flexDirection: 'column',
                    maxWidth: '720px',
                    width: '100%',
                    margin: '0 auto',
                    padding: '0 24px',
                }}
            >
                <Typography variant="spectral_32" sx={{ textAlign: 'center' }}>
                    The invite code does not exist
                </Typography>
                <img src={EmptySRC} alt="Celebration " />
                <Typography variant="manrope_14">Please, contact Homegrown support team</Typography>
            </Box>
        </>
    );
};
