import React, { useLayoutEffect, useRef, useState } from 'react';
//
import { Box, Button, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
//
import HomegrownSVG from 'Assets/homegrown.svg';
import { useShowToast } from 'Helpers/hooks/useShowToast.tsx';
import EntityRafikiSVG from 'Assets/images/EntityRafiki.svg';
import ClientLoader from '@Client/components/ClientLoader.tsx';
import { EMAILS_ENTRYPOINT } from '../../app-constants.ts';
import { AUTH_CONFIG } from 'Services/auth';
import { Auth0Client } from '@auth0/auth0-spa-js';
import { authRoute } from '../../constants.ts';

const VERIFY_NEW_EMAIL_ENDPOINT = 'verify_change';

const auth0Client = new Auth0Client({
    domain: AUTH_CONFIG.domain,
    clientId: AUTH_CONFIG.clientId,
});

export const UserConfirmEmail = () => {
    const location = useLocation();
    const code = new URLSearchParams(location.search).get('code');

    const { showSuccess, showError } = useShowToast();

    const hasRun = useRef(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [isSuccess, setIsSuccess] = useState<boolean | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useLayoutEffect(() => {
        if (!code || hasRun.current) return;

        hasRun.current = true;
        setLoading(true);

        const verifyNewEmail = async () => {
            try {
                const response = await axios.post(
                    `${EMAILS_ENTRYPOINT}/${VERIFY_NEW_EMAIL_ENDPOINT}`,
                    { code }
                );

                if (response?.data?.status === 'successful') {
                    setIsSuccess(true);
                    await auth0Client.logout({ openUrl: false });
                    return;
                }
            } catch (error: unknown) {
                if (axios.isAxiosError(error) && error.response?.data?.status) {
                    const messages: Record<string, string> = {
                        'code is used': 'You have already successfully changed your email.',
                        'code invalid':
                            'This link is not valid. Please request an email change again on the Profile page.',
                        'code expired':
                            'The email change link has expired. Please request an email change again on the Profile page.',
                        'email is already used for another account':
                            'Email is already associated with another account. Please choose a different email.',
                    };

                    setErrorMessage(
                        messages[error.response.data.status] || 'An unexpected error occurred.'
                    );
                } else {
                    setErrorMessage('A server error occurred. Please try again later.');
                }

                setIsSuccess(false);
            } finally {
                setLoading(false);
            }
        };

        void verifyNewEmail();
    }, [code, showSuccess, showError]);

    return (
        <>
            <Box
                sx={{
                    padding: '20px 24px',
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: '32px',
                }}
            >
                <img src={HomegrownSVG} alt="Homegrown Logo" width={188} height={32} />
            </Box>

            {loading ? (
                <ClientLoader />
            ) : (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '32px',
                        flexDirection: 'column',
                        maxWidth: '720px',
                        width: '100%',
                        margin: '0 auto',
                        padding: '0 24px',
                        textAlign: 'center',
                    }}
                >
                    <Typography variant="spectral_32">Email change</Typography>
                    <img src={EntityRafikiSVG} alt="img" />
                    <Typography variant="manrope_14">
                        {isSuccess ? 'Email has been successfully changed!' : errorMessage}
                    </Typography>
                    <Button
                        variant="contained"
                        size="large"
                        fullWidth
                        onClick={() => window.location.replace(authRoute)}
                    >
                        Go to auth page
                    </Button>
                </Box>
            )}
        </>
    );
};
