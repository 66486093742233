import sortBy from 'lodash/sortBy';
import { saveAs } from 'file-saver';
import has from 'lodash/has';
import startCase from 'lodash/startCase';

import dayJs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';
//
import { baseURL } from 'app-constants.ts';
import { DEFAULT_LOCALE, INVESTOR_DOC_TYPES } from 'constants.ts';
import { GridColumnsConfigType } from 'Apps/client-app/components/DataGrid.tsx';
import { ColumnConfig } from 'Components/PaginatedTable.tsx';
import { TRANSACTION_STATUSES } from 'Services/plaid/constants.ts';
import dayjs from 'dayjs';

dayJs.extend(utc);
dayJs.extend(timezone);
dayJs.extend(advancedFormat);

export const getAbsoluteRouteURL = (route: string) =>
    new URL(route.replace(/^\//, ''), baseURL.href.replace(/([^/])$/, '$1/')).href;

export const getRelativePath = (root: string, absolutePath = '') => {
    const [, ...paths] = absolutePath.split(root);
    return root + paths.join(root);
};

export function thousandsFormat(n: number) {
    const [int, dec = ''] = Math.abs(n).toString().split('.');
    const intTransform = int
        .split('')
        .reverse()
        .reduce<string[]>((s, v, idx, ar) => {
            const pos = idx + 1;
            if (pos % 3 === 0 && pos !== ar.length) {
                return s.concat([v, ',']);
            } else return s.concat(v);
        }, [])
        .reverse()
        .join('');
    const value = intTransform + '.' + dec.padStart(2, '0');
    return n > 0 ? value : '-' + value;
}

export function getUserLocale() {
    if (typeof window === 'undefined') return DEFAULT_LOCALE;
    const locale = window.Intl?.NumberFormat().resolvedOptions().locale;
    return locale || window.navigator.language || window.navigator.languages?.[0] || DEFAULT_LOCALE;
}

export function dateRepresent(
    value: Date | string | null | undefined,
    options?: Intl.DateTimeFormatOptions
): string {
    if (!value) return 'N/A';
    return new Date(value).toLocaleDateString(getUserLocale(), options);
}

export function moneyRepresent(
    amount: number | null | undefined,
    currency: string | null | undefined = 'USD',
    compact?: boolean
) {
    const userLocale = getUserLocale();
    let moneyString: string;
    if (amount == null) return 'N/A';
    if (!currency) return thousandsFormat(amount);
    if (typeof Intl === 'undefined') {
        moneyString = `${thousandsFormat(amount)} ${currency}`;
    } else {
        moneyString = new Intl.NumberFormat([userLocale, DEFAULT_LOCALE], {
            style: 'currency',
            currency,
            notation: compact ? 'compact' : undefined,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(amount);
    }
    return moneyString;
}

export function percentRepresent(value: number, maximumFractionDigits = 2) {
    return new Intl.NumberFormat([getUserLocale(), DEFAULT_LOCALE], {
        style: 'percent',
        maximumFractionDigits,
    }).format(Number.isFinite(value) ? value : 0);
}

export function isNotFalsy<D>(v: D | null | undefined | false): v is D {
    return v != null && v !== false;
}

export function isNotFile<T>(v: File | T): v is T {
    return !(v instanceof File);
}

export function isFile<T>(v: File | T): v is File {
    return v instanceof File;
}

export function isSameFiles(file1: File, file2: File) {
    return (
        file1.name === file2.name &&
        file1.size === file2.size &&
        file1.lastModified === file2.lastModified
    );
}

export const parseTime = (seconds: number) => {
    const min = Math.floor(seconds / 60);
    const sec = seconds % 60;
    const pad0 = (n: number) => String(n).padStart(2, '0');
    return `${pad0(min)}:${pad0(sec)}`;
};

export function formatString(format: string, value: string) {
    const pureValue = value.replace(/\D/g, '');

    const { string } = format.split('').reduce(
        (result, x) => {
            const { string, position, pureValue } = result;
            if (pureValue.at(position) == null) return result;
            if (x === 'x') {
                return {
                    ...result,
                    string: string + pureValue.at(position),
                    position: position + 1,
                };
            } else {
                return { ...result, string: string + x };
            }
        },
        { string: '', position: 0, pureValue }
    );
    return string;
}

export function getPayoutId(curId?: string | string[]) {
    // payout_id format YYYY-MM/X
    const now = new Date();
    const nowMonth = String(now.getMonth() + 1).padStart(2, '0');
    const newId = `${now.getFullYear()}-${nowMonth}`;

    if (Array.isArray(curId)) {
        curId = sortBy(curId, (v) => Number(v.split('/')[1] || 0)).at(-1);
    }
    if (!curId) return newId;
    const [curBase, curX] = curId.split('/');
    if (curBase === newId) return newId + '/' + (+curX + 1 || 1);
    else return newId;
}

export function getMonthStart(date = new Date()) {
    date.setUTCDate(1);
    date.setUTCHours(0, 0, 0, 0);
    return date.toISOString().replace('Z', '+00:00');
}

export function generateId(
    prefix: '' | undefined,
    startN?: number,
    negative?: boolean
): () => number;
export function generateId(prefix: string, startN?: number, negative?: boolean): () => string;
export function generateId(prefix = '', startN = 0, negative = false) {
    return () => {
        const newId = negative ? --startN : startN++;
        return prefix ? `${prefix}${newId}` : newId;
    };
}

export function isActive<T extends { active?: boolean }, Active extends { active: true }>(
    item: Active | T
): item is Active {
    return item.active === true;
}

export function isDeactivated<
    T extends { active?: boolean },
    Deactivated extends { active: false },
>(item: T | Deactivated): item is Deactivated {
    return item.active === false;
}

export function isIssuedId(
    doc: InvestorDocumentType
): doc is InvestorDocumentType<INVESTOR_DOC_TYPES.IssuedId> {
    return doc.type === INVESTOR_DOC_TYPES.IssuedId;
}

export function isProof(
    doc: InvestorDocumentType
): doc is InvestorDocumentType<INVESTOR_DOC_TYPES.Proof> {
    return doc.type === INVESTOR_DOC_TYPES.Proof;
}

export function isPayout<P extends { payout_amount: number }, T extends object>(
    item: P | T
): item is P {
    return has(item, 'payout_amount');
}

export function isInvestment<I extends { invested_amount: number }, T extends object>(
    item: I | T
): item is I {
    return has(item, 'invested_amount');
}

export const isImage = (file: File | UserFileType) => {
    return /^image\//.test(isFile(file) ? file.type : file.mimeType);
};

export function downloadCSV(csvData: (string | number)[][], name: string) {
    const CSVSeparator = ',';
    const csvString = csvData.map((csvRow) => csvRow.join(CSVSeparator)).join('\n');

    const blob = new Blob([csvString], {
        type: 'text/cvs;charset=utf-8',
    });
    saveAs(blob, `${name}.csv`);
}

export function nextMonthDate(dateString: string | Date, monthAmount?: number): Date;
export function nextMonthDate(
    dateString: string | Date,
    monthAmount: number,
    options: Intl.DateTimeFormatOptions
): string;
export function nextMonthDate(
    dateString: string | Date,
    monthAmount = 1,
    options?: Intl.DateTimeFormatOptions
) {
    const date = new Date(dateString);
    date.setMonth(date.getMonth() + monthAmount);
    return options ? dateRepresent(date, options) : date;
}

export const returnToHandler = {
    setReturnTo(returnToPath?: string) {
        returnToPath && localStorage.setItem('returnTo', returnToPath);
    },
    pullReturnToPath() {
        const returnTo = localStorage.getItem('returnTo');
        localStorage.removeItem('returnTo');
        return returnTo;
    },
};

export const dateToMonthValue = (monthDayjs: Dayjs) =>
    monthDayjs.startOf('month').format('YYYY-MM-DD');

export const getMonthOptions = () => {
    const curDayjs = dayJs();
    const monthsDates: Dayjs[] = [];

    for (let i = -12; i <= 12; i++) {
        monthsDates.push(curDayjs.add(i, 'month'));
    }

    return monthsDates.map((monthDate) => ({
        value: dateToMonthValue(monthDate),
        label: dateRepresent(monthDate.toDate(), {
            month: 'long',
            year: 'numeric',
        }) as string,
    }));
};

export function getCSVData<D extends object>(config: GridColumnsConfigType<D>, data: D[]) {
    const header = config.map((conf) =>
        parseString(startCase(conf.exportHeader ?? String(conf.header)))
    );
    const rows = data.map((dataItem) =>
        config.map((conf) => {
            const value = conf.value(dataItem);
            const csvValue = conf.valueExport
                ? conf.valueExport(value, dataItem)
                : value
                ? String(value)
                : '';
            if (typeof csvValue === 'number') return csvValue;
            else return parseString(csvValue);
        })
    );
    return ([] as Array<string | number>[]).concat([header], rows);

    function parseString(str: string) {
        return `"${str.replaceAll('"', '""')}"`;
    }
}

export function listToOptions<T>(items: Array<T>) {
    return items.map((value) => ({ label: String(value), value }));
}

export function routingNumberValidation(rNumber: string) {
    if (!/\d{9}/.test(rNumber)) return false;

    const d = rNumber.split('').map(Number);
    const sum = 3 * (d[0] + d[3] + d[6]) + 7 * (d[1] + d[4] + d[7]) + (d[2] + d[5] + d[8]);

    return sum % 10 === 0;
}

export function getAccountNumberMask(accountNumber?: string) {
    return accountNumber?.slice(-4);
}

export const capitalizeFirstLetter = (string: string | null | undefined) => {
    return string ? string[0].toUpperCase() + string.slice(1) : '';
};

export function formatDate(date: string | Date | undefined): string {
    if (!date || !dayJs(date).isValid()) {
        return '';
    }
    return dayJs(date).format('MMMM YYYY');
}

export function addOneMonth(date: string | Date | undefined): string {
    if (!date || !dayJs(date).isValid()) {
        return '';
    }
    return dayJs(date).add(1, 'month').format('MMMM YYYY');
}

export const getLastThreeYears = (): number[] => {
    const currentYear = new Date().getFullYear();
    return [currentYear, currentYear - 1, currentYear - 2];
};

export const getLast24Months = () => {
    const months = [];
    const currentDate = new Date();

    for (let i = 0; i < 24; i++) {
        const date = new Date(currentDate.getFullYear(), currentDate.getMonth() - i, 1);
        const monthName = date.toLocaleString('en-US', { month: 'long' });
        const year = date.getFullYear();
        months.push(`${monthName} ${year}`);
    }

    return months;
};

export const getPreviousRevenueMonth = (): string => {
    return dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
};

export const getYearISORange = (
    year: number | string
): { startYearISO: string; endYearISO: string } => {
    const numericYear = typeof year === 'string' ? parseInt(year, 10) : year;
    const startYearISO = `${numericYear}-01-01T00:00:00Z`;
    const endYearISO = `${numericYear + 1}-01-01T00:00:00Z`;
    return { startYearISO, endYearISO };
};

export const getMonthISORange = (
    monthYear: string
): { startMonthISO: string; endMonthISO: string } => {
    const [monthName, yearStr] = monthYear.split(' ');
    const year = parseInt(yearStr, 10);

    const monthMapping: { [key: string]: string } = {
        January: '01',
        February: '02',
        March: '03',
        April: '04',
        May: '05',
        June: '06',
        July: '07',
        August: '08',
        September: '09',
        October: '10',
        November: '11',
        December: '12',
    };
    const month = monthMapping[monthName];
    const startMonthISO = `${year}-${month}-01T00:00:00Z`;
    const nextMonth = parseInt(month, 10) + 1;
    const endMonthISO =
        nextMonth > 12
            ? `${year + 1}-01-01T00:00:00Z`
            : `${year}-${String(nextMonth).padStart(2, '0')}-01T00:00:00Z`;

    return { startMonthISO, endMonthISO };
};

export function downloadMUITableCSV(csvData: (string | number)[][], name: string) {
    const CSVSeparator = ',';
    const csvString = csvData.map((csvRow) => csvRow.join(CSVSeparator)).join('\n');

    const blob = new Blob([csvString], {
        type: 'text/csv;charset=utf-8',
    });
    saveAs(blob, `${name}.csv`);
}

export function getMUITableCSVData<T>(
    columns: ColumnConfig<T>[],
    data: T[]
): (string | number)[][] {
    const header = columns.map((col) => col.label);

    const rows = data.map((dataItem) =>
        columns.map((col) => {
            const value = dataItem[col.id];
            return parseString(String(value));
        })
    );

    return [header, ...rows];

    function parseString(str: string): string {
        return `"${str.replaceAll('"', '""')}"`;
    }
}

export function getFileName(
    deal: string,
    industry: string,
    year: number | string,
    month: string,
    baseName: string
): string {
    const dealPart = deal !== 'All' ? deal : '';
    const industryPart = industry !== 'All' ? industry : '';
    const yearPart = year === 'All' ? 'AllYears' : year.toString();
    const monthPart = month !== 'All' ? month.replace(' ', '_').toLowerCase() : '';

    if (dealPart && industryPart) {
        return `${dealPart}_${industryPart}_${baseName}_${monthPart || yearPart}`;
    } else if (dealPart) {
        return `${dealPart}_${baseName}_${monthPart || yearPart}`;
    } else {
        return `${baseName}_${monthPart || yearPart}`;
    }
}

export const formatSSN = (value: string) => {
    const numbers = value.replace(/\D/g, '');
    if (numbers.length <= 3) {
        return numbers;
    } else if (numbers.length <= 5) {
        return `${numbers.slice(0, 3)}-${numbers.slice(3)}`;
    } else {
        return `${numbers.slice(0, 3)}-${numbers.slice(3, 5)}-${numbers.slice(5, 9)}`;
    }
};

export const validateSSN = (value: string) => {
    const ssnPattern = /^\d{3}-\d{2}-\d{4}$/;
    if (!ssnPattern.test(value)) {
        return 'Invalid SSN format. Example: 123-45-6789';
    }
    return true;
};

export const validatePhoneNumber = (phone: string): string => {
    if (!phone.startsWith('+')) {
        return '+1' + phone.replace(/^\+/, '');
    }
    return phone.startsWith('+1') ? phone : '+1' + phone.slice(1);
};

export const validateEmail = (value: string) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(value)) {
        return 'Email must be a valid email address';
    }
    return true;
};

export const getBankAccount = (accountEntity: BankAccountEntity): string => {
    if (accountEntity.plaid && !accountEntity.treasuryprime) {
        return `${accountEntity.plaid.institution_meta_data.name} ****${
            accountEntity.plaid.account_meta_data.accounts?.[0]?.mask ||
            accountEntity.plaid.account_meta_data.mask
        }`;
    } else if (!accountEntity.plaid && accountEntity.treasuryprime) {
        const accountNumberLast4 = accountEntity.treasuryprime.ach.account_number.slice(-4);
        return `${accountEntity.treasuryprime.name_on_account} ****${accountNumberLast4}`;
    }
    return 'N/A';
};

export const roundDownToTwoDecimals = (value: number): string => {
    return (Math.floor(value * 100) / 100).toFixed(2);
};

export const nameSplit = (fullName: string | undefined | null) => {
    if (!fullName) return '';

    const nameParts = fullName.split(' ');
    const firstInitial = nameParts[0]?.[0] || '';
    const secondInitial = nameParts[1]?.[0] || '';

    return `${firstInitial}${secondInitial}`;
};

export const validateImageFile = (
    file: File,
    maxSizeMB: number = 5,
    maxResolution: number = 2000
): Promise<void> => {
    return new Promise((resolve, reject) => {
        const fileSizeLimit = maxSizeMB * 1024 * 1024;
        if (file.size > fileSizeLimit) {
            return reject(
                `File size exceeds the ${maxSizeMB}MB limit. Please upload a smaller file.`
            );
        }

        const img = new Image();
        img.src = URL.createObjectURL(file);

        img.onload = () => {
            const { width, height } = img;

            if (width > maxResolution || height > maxResolution) {
                reject(
                    `Image resolution exceeds ${maxResolution}x${maxResolution} pixels. Please upload a smaller image.`
                );
            } else {
                resolve();
            }
        };

        img.onerror = () => {
            reject('There was an error loading the image. Please try again.');
        };
    });
};

export const validateName = (value: string): string | true => {
    const nameRegex =
        /^[a-zA-Z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u01FF]+([ \-'][a-zA-Z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u01FF]+)+[.]?$/;
    if (!nameRegex.test(value)) {
        return 'Only alphabetic characters, apostrophes, hyphens, periods, and spaces are allowed. Name must consist of at least two words.';
    }
    return true;
};

export const removeEmojis = (value: string): string => {
    return value.replace(
        /[\u{1F600}-\u{1F64F}\u{2702}-\u{27B0}\u{1F680}-\u{1F6FF}\u{1F300}-\u{1F5FF}\u{1F1E6}-\u{1F1FF}\u{1F900}-\u{1F9FF}\u{2600}-\u{26FF}]/gu,
        ''
    );
};

export const validateIssueDate = (issueDate: string, birthdate?: string) => {
    const selectedIssueDate = new Date(issueDate);
    const currentDate = new Date();
    if (selectedIssueDate > currentDate) {
        return 'Issue date cannot be a future date.';
    }
    if (birthdate) {
        const selectedBirthdate = new Date(birthdate);
        if (selectedIssueDate < selectedBirthdate) {
            return 'Issue date cannot be before the birthdate.';
        }
    }
    return true;
};

export const validateIssueDateWithoutBirthdate = (value: string): true | string => {
    const selectedIssueDate = new Date(value);
    const currentDate = new Date();
    if (selectedIssueDate > currentDate) {
        return 'Issue date cannot be a future date.';
    }
    const hundredYearsAgo = new Date();
    hundredYearsAgo.setFullYear(currentDate.getFullYear() - 100);
    if (selectedIssueDate < hundredYearsAgo) {
        return 'Issue date cannot be more than 100 years ago.';
    }

    return true;
};

export const validateBirthdate = (date: string) => {
    const selectedDate = new Date(date);
    const currentDate = new Date();
    if (selectedDate > currentDate) {
        return 'Birthdate cannot be a future date.';
    }
    const currentDateMinus18Years = new Date(
        currentDate.getFullYear() - 18,
        currentDate.getMonth(),
        currentDate.getDate()
    );
    if (selectedDate > currentDateMinus18Years) {
        return 'You must be at least 18 years old to onboard.';
    }

    const currentDateMinus100Years = new Date(
        currentDate.getFullYear() - 100,
        currentDate.getMonth(),
        currentDate.getDate()
    );

    if (selectedDate < currentDateMinus100Years) {
        return 'Birthdate cannot be more than 100 years ago.';
    }

    return true;
};

export const minBirthdate = `${new Date().getFullYear() - 100}-01-01`;

export const validateExpirationDate = (date: string): true | string => {
    const year = new Date(date).getFullYear();
    if (year > 9999) {
        return 'Year cannot exceed 9999';
    }
    return true;
};

export const validateStreetAddress = (address: string) => {
    const streetAddressRegex =
        /^(?:(\d{1,5}\s\w+(\s\w+)*(\s(?:[A-Za-z]+\.?|#?\d+\w?))?)|(\w+(\s\w+)*\s\d{1,5}))$/;

    if (!streetAddressRegex.test(address)) {
        return 'Invalid street address';
    }
    return true;
};

export const validateUnitField = (unit: string) => {
    if (!unit) return true;
    const unitFieldRegex = /^[a-zA-Z0-9\s]+$/;

    if (!unitFieldRegex.test(unit)) {
        return 'Unit field must contain only letters, numbers, and spaces';
    }
    return true;
};

export const validateCityName = (city: string) => {
    const cityNameRegex = /^[a-zA-Z\u00C0-\u017F]+(['\s-][a-zA-Z\u00C0-\u017F]+)*$/;

    if (!cityNameRegex.test(city)) {
        return 'Invalid city name';
    }
    return true;
};
export const validateIncome = (income: string) => {
    const incomeRegex = /^\$?[0-9]+(\.[0-9]+)?$/;

    if (!incomeRegex.test(income)) {
        return 'Only digits and "." are allowed';
    }
    return true;
};

export const validateZipCode = (zipCode: string) => {
    if (!zipCode) return true;
    const zipCodeRegex = /^[a-zA-Z0-9\s-]{3,10}$/;

    if (!zipCodeRegex.test(zipCode)) {
        return 'Invalid zip or postal code';
    }
    return true;
};

export function getRelativeTime(notificationTime: string): string {
    const now = new Date();
    const notificationDate = new Date(notificationTime);
    const diffInSeconds = Math.floor((now.getTime() - notificationDate.getTime()) / 1000);
    const intervals = [
        { label: 'year', seconds: 31536000 },
        { label: 'month', seconds: 2592000 },
        { label: 'week', seconds: 604800 },
        { label: 'day', seconds: 86400 },
        { label: 'hour', seconds: 3600 },
        { label: 'minute', seconds: 60 },
        { label: 'second', seconds: 1 },
    ];
    for (const interval of intervals) {
        const count = Math.floor(diffInSeconds / interval.seconds);
        if (count >= 1) {
            return `${count}${interval.label.charAt(0)} ago`;
        }
    }
    return 'Just now';
}

export const parseErrorMessage = (errorMessage: string) => {
    if (!errorMessage) return 'Please contact support';

    const normalizedErrorMessage = errorMessage
        .replace(/'/g, '"')
        .replace(/datetime\.datetime\([^)]+\)/g, '"Invalid Date"')
        .replace(/None/g, 'null')
        .replace(/\),/g, ',');

    if (normalizedErrorMessage.startsWith('{') && normalizedErrorMessage.endsWith('}')) {
        try {
            const parsedError = JSON.parse(normalizedErrorMessage);

            if (parsedError.decision_rationale === 'NSF') {
                return 'Non-sufficient funds';
            }

            if (parsedError.error && parsedError.error.display_message) {
                const displayMessage = parsedError.error.display_message;

                if (
                    displayMessage.includes(
                        'user.phone_number must have a valid phone number format'
                    )
                ) {
                    return 'Invalid phone number format. Please contact support.';
                }

                return displayMessage;
            }

            return (
                parsedError.decision_rationale ||
                parsedError.display_message ||
                (parsedError.failure_reason && parsedError.failure_reason.description) ||
                errorMessage
            );
        } catch (error) {
            console.error('Failed to parse error message:', error);
            return errorMessage;
        }
    }

    return errorMessage;
};

export const truncateFileName = (name: string, maxLength: number = 20) => {
    if (name.length <= maxLength) return name;
    const extension = name.split('.').pop();
    const nameWithoutExtension = name.slice(0, name.length - (extension?.length || 0) - 1);
    return `${nameWithoutExtension.slice(0, maxLength / 2)}...${nameWithoutExtension.slice(
        -maxLength / 2
    )}.${extension}`;
};

export const formatDateToMonthYear = (dateString: string): string => {
    return dayjs(dateString).format('MMMM YYYY');
};

export const calculateTotalAmounts = (
    data: DataItem[],
    activeStatuses: TRANSACTION_STATUSES[]
): CalculateTotalsResult => {
    const totalGrossAmount = data
        .filter((item) => activeStatuses.includes(item.status as TRANSACTION_STATUSES))
        .reduce((acc, item) => acc + (item.grossAmount || 0), 0);

    const totalNetAmount = data
        .filter((item) => activeStatuses.includes(item.status as TRANSACTION_STATUSES))
        .reduce((acc, item) => acc + (item.netAmount || 0), 0);

    const totalInvestedAmount = data
        .filter((item) => activeStatuses.includes(item.status as TRANSACTION_STATUSES))
        .reduce((acc, item) => acc + (item.investedAmount || 0), 0);

    return { totalGrossAmount, totalNetAmount, totalInvestedAmount };
};

export function analyzeFile(file: File) {
    return new Promise((resolve) => {
        const reader = new FileReader();

        reader.onload = (event) => {
            const arrayBuffer = event.target?.result as ArrayBuffer;
            const uint8Array = new Uint8Array(arrayBuffer);

            resolve({
                name: file.name,
                size: file.size,
                type: file.type,
                lastModified: new Date(file.lastModified).toISOString(),
                firstBytes: Array.from(uint8Array.slice(0, 20))
                    .map((byte) => byte.toString(16).padStart(2, '0'))
                    .join(' '),
                hexSignature: uint8Array
                    .slice(0, 4)
                    .reduce((acc, byte) => acc + byte.toString(16).padStart(2, '0'), ''),
                isImage: /^image\//.test(file.type),
                isPdf: file.type === 'application/pdf',
            });
        };

        reader.onerror = () => {
            resolve({
                name: file.name,
                size: file.size,
                type: file.type,
                lastModified: new Date(file.lastModified).toISOString(),
                error: 'Failed to read file content',
            });
        };

        reader.readAsArrayBuffer(file.slice(0, 20));
    });
}

export const formatAddress = ({
    street_address,
    address,
    unit,
    city,
    state,
    zip_code,
}: {
    street_address?: string;
    address?: string;
    unit?: string;
    city?: string;
    state?: string;
    zip_code?: string;
}): string => {
    return [street_address || address, unit, city, state, zip_code]
        .filter((item): item is string => Boolean(item))
        .map((str) => str.trim())
        .join(', ');
};
